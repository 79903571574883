<template>
  <div :id="modalId" class="uk-modal" uk-modal="bg-close: false; esc-close: false; stack: true">
    <div class="uk-modal-dialog uk-margin-auto-vertical uk-width-1-2">
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-text-left">{{ $t('Pages.Application.PushNotificationManagement') }}</h2>
      </div>

      <div class="uk-modal-body">
        
        <div class="uk-container">
          <div class="uk-width-expand@m">

            <div class="uk-container">

              <form class="uk-form-horizontal">

                <div class="uk-margin-small">
                  <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                    {{$t("Pages.Application.PushNotificationForm.Title")}}
                    <span class="uk-text-danger">*</span>
                  </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input type="text" class="uk-input" :class="{ 'invalid-border': !isNameValid }" :placeholder="$t('Pages.Application.Placeholder.Title')" v-model.trim="form.content.name" @blur="validateOnBlur('content', 'name')">
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!isNameValid" uk-icon="icon: warning"></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!isNameValid" >{{this.errors.content.name}}</span>
                    </div>
                  </div>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                    {{$t("Pages.Application.PushNotificationForm.Content")}}
                    <span class="uk-text-danger">*</span>
                  </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <textarea class="uk-textarea" :class="{ 'invalid-border': !isContentValid }" :placeholder="$t('Pages.Application.PushNotificationForm.ContentMessage')" v-model.trim="form.content.content" @blur="validateOnBlur('content', 'content')"></textarea>
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!isContentValid" uk-icon="icon: warning"></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!isContentValid" >{{this.errors.content.content}}</span>
                    </div>
                  </div>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                    {{$t("Pages.Application.PushNotificationForm.DeepLinkVideoId")}}
                  </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input type="text" class="uk-input" :class="{ 'invalid-border': !isDeepLinkValid }"  :placeholder="$t('Pages.Application.Placeholder.DeepLinkVideoId')" v-model.trim="form.content.deepLink">
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!isDeepLinkValid" uk-icon="icon: warning"></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!isDeepLinkValid" >{{this.errors.content.deepLink}}</span>
                    </div>
                  </div>
                </div>

                <div class="uk-margin-small" v-if="appSelection === 'enabled'">
                  <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                    {{$t("Pages.PushNotifications.Apps")}}
                    <span class="uk-text-danger">*</span>
                  </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <Multiselect
                        :dataSet="appsForDropdown || []"
                        v-model="form.selectedApps"
                        :errors="isAppsValid ? null : this.errors.content.selectedApps || undefined"
                        :placeholder="placeholder"
                        :resetForm="resetForm"
                        @blur="placeholder = $t('Pages.PushNotifications.SelectApplication')"
                        @focus="placeholder = $t('Pages.PushNotifications.ApplicationsDisplayed')"
                      />
                    </div>
                    <div class=" uk-width-1-1 uk-text-left" v-if="appSelection === 'enabled' && !form.selectedApps.length">
                      <span class="uk-text-meta uk-text-italic" style="font-size:.7rem"><span style="width:15px" class="uk-margin-small-right" uk-icon="icon: warning"></span>{{$t('Pages.PushNotifications.ApplicationsDisplayed')}}</span>
                    </div>
                  </div>
                </div>

                <div class="uk-margin-small" >
                  <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                    {{$t("Pages.Application.PushNotificationForm.Schedule")}}
                  </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <vue-toggles width="60" :value="form.content.schedule" @click="form.content.schedule = !form.content.schedule"/>
                    </div>
                  </div>
                </div>
                  <br>

                <div v-if="isSchedulable">

                  <div class="uk-margin-small">
                    <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                      {{$t("Pages.Application.PushNotificationForm.ScheduleTime")}}
                      <span class="uk-text-danger">*</span>
                    </label>
                    <div class="uk-form-controls uk-text-left">
                      <div class="uk-inline uk-width-1-2" >
                          <InputDatePicker
                              id="start"
                              style="width: 420px"
                              v-model="form.schedule.scheduleTime"
                              class="uk-margin-small-right uk-margin-small-top"
                              enable-time
                              :min-date="minDate"
                              :placeholder="$t('Pages.Application.PushNotificationForm.ScheduleTime')"
                              required
                              @blur="validateForm('schedule')"
                          />
                          <div class="uk-text-danger uk-text-left" v-if="!isDateTimeValid">{{ this.errors.schedule.scheduleTime}}
                            <span class="invalid-icon" v-if="!isDateTimeValid" uk-icon="icon: warning"></span>
                          </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="uk-margin-small">
                    <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                      {{$t("Pages.Application.PushNotificationForm.ScheduleType")}}
                    </label>
                    <div class="uk-form-controls">
                      <div class="uk-inline uk-width-1-1">
                        <div class="uk-margin uk-child-width-auto uk-text-left">
                            <label><input class="uk-radio" type="radio" name="radio2" value="LOCAL" checked v-model="form.schedule.timezoneLocale"> {{ $t('Pages.Application.PushNotificationForm.NotifyIrrespectiveOfTimezone')}}</label>
                            <br>
                            <label><input class="uk-radio" type="radio" name="radio2" value="GLOBAL" v-model="form.schedule.timezoneLocale"> {{$t('Pages.Application.PushNotificationForm.NotifyRespectiveOfTimezone')}}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>                

              </form>
            </div>

          </div>


        </div>

      </div>

      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-right" type="button"  @click.prevent="clearForm()">{{ $t( 'Actions.Cancel' ) }}</button>
        <button v-if="!saving" class="uk-button uk-button-primary uk-margin-small-left" @click.prevent="createPushNotification();">{{ $t('Actions.Send') }}</button>
        <button v-if="saving" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>

      </div>

    </div>

  </div>
</template>

<script>
import InputDatePicker, { dateFormat } from '@/components/InputDatePicker';
import moment from 'moment';
import Notification from '@/components/util/Notification';
import { mapState } from 'vuex'
import Multiselect from '@/components/multiselect/Multiselect'

export default {
  name: 'PushNotification',
  props: {
    appSelection: {
      type: String,
      default: "disabled"
    }
  },
  components: {
    InputDatePicker,
    Multiselect
  },
  data () {
    return {
      uuidRE: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
      saving: false,
      route: this.$route,
      dontClearForm: false,
      placeholder: this.$t('Pages.PushNotifications.SelectApplication'),
      resetForm: 0,
      form: {
        selectedApps: [],
        content: {
          name: '',
          content: '',
          deepLink: '',
          schedule: false
        },
        schedule: {
          scheduleTime: '',
          timezoneLocale: ''
        }
      },
      errors: {
        content: {
          name: false,
          content: false,
          deepLink: false,
          selectedApps: false
        },
        schedule: {
          scheduleTime: false
        }
      }
    }
  },
  computed: {
    appsForDropdown () {
      const apps = []
      if (this.apps?.length) {
        this.apps.forEach(app => {
          if (app.type !== 'HTML5' && (app.pushNotificationConfig?.internal?.apiKey || app.pushNotificationConfig?.internal?.keyId)) {
            apps.push({ label: app.name, value: app.id, selected: false })
          }
        })
      }
      return apps
    },
    ...mapState({
      apps: state => state.venom.org?.orgApps?.nodes
    }),
    minDate () {
        return moment().format( dateFormat );
    },
    isNameValid () {
      if (this.form.content.name.length) {
        return this.validateInput('content', 'name');
      } else {
        return this.errors.content.name ? false: true;
      }
    },
    isContentValid () {
      if (this.form.content.content.length) {
        return this.validateInput('content', 'content');
      } else {
        return this.errors.content.content ? false: true;
      }
    },
    isDeepLinkValid () {
      return this.validateInput('content', 'deepLink');
    },
    isAppsValid () {
      return this.validateInput('content', 'selectedApps');
    },
    isDateTimeValid () {
      if (this.form.schedule.scheduleTime?.length) {
        this.validateForm('schedule')
        return this.form.schedule.scheduleTime ? moment().format() < this.form.schedule.scheduleTime : this.errors.schedule.scheduleTime ? false : true;
      }
      if (this.form.content.schedule && !this.form.schedule.scheduleTime?.length) {
        this.validateForm('schedule')
        return false
      }
      return this.errors.schedule.scheduleTime === false ? true : false;
    },
    isSchedulable () {
      return this.form.content?.schedule
    },
    modalId () {
      return 'push-notification-modal' + (this.appSelection === 'enabled' ? '-multi-app' : '')
    }
  },
  mounted () {
    if (this.dontClearForm) return
    this.clearForm()
  },
  methods: {
    clearForm () {
      this.resetForm++
      this.form.apps = []
      this.form.content = {
        name: '',
        content: '',
        deepLink: '',
        schedule: false,
        selectedApps: []
      }
      this.form.schedule = {
        scheduleTime: '',
        timezoneLocale: 'LOCAL'
      }
      this.errors.content = {
        name: false,
        content: false,
        deepLink: false,
        selectedApps: false
      }
      this.errors.schedule = {
        scheduleTime: false
      }
    },
    validateOnBlur(section, field) {
      if (this.errors[section][field]) return false
      if (field !== 'deepLink') {
        if (this.form[section][field].length < 3) {
          this.errors[section][field] = this.$t("Pages.Application.PushNotificationForm.MandatoryField");
        }
      }
    },
    validateInput (section, field) {
      if (field === 'deepLink') {
        if (this.form.content.deepLink.length && !this.uuidRE.test(this.form.content.deepLink)) {
          this.errors.content.deepLink = this.$t("Pages.Application.PushNotificationForm.EnterValidUuid");
          return false
        } else {
          this.errors.content.deepLink = false;
        }
      }
      if (field === 'selectedApps') {
        if (this.appSelection === 'enabled') {
          if (this.form.selectedApps?.length) {
            this.errors[section][field] = false
          }
        }
        return !this.errors[section][field]
      }
      if (this.form[section][field].length && this.form[section][field]?.length < 3) {
        this.errors[section][field] = this.$t("Pages.Application.PushNotificationForm.Min3Chars");
        return false
      } else {
        this.errors[section][field] = false;
        return true;
      }
    },
    validateForm (section) {
      if (section === 'content') {
        if (!this.form.content.name) {
          this.errors.content.name = this.$t("Pages.Application.PushNotificationForm.MandatoryField");
        }
        if (!this.form.content.content) {
          this.errors.content.content = this.$t("Pages.Application.PushNotificationForm.MandatoryField");
        }
        if (this.form.content.deepLink.length && !this.uuidRE.test(this.form.content.deepLink)) {
          this.errors.content.deepLink = this.$t("Pages.Application.PushNotificationForm.EnterValidUuid");
        }
        if (this.appSelection === 'enabled') {
          if (!this.form.selectedApps || !this.form.selectedApps.length) {
            this.errors.content.selectedApps = this.$t("Pages.PushNotifications.Errors.AppsMandatory")
          }
        }
      } else {
        if (!this.form.schedule.scheduleTime?.length) {
          this.errors.schedule.scheduleTime = this.$t("Pages.Application.PushNotificationForm.MandatoryField");
          return false;
        } else {
          this.errors.schedule.scheduleTime = false
        }
        if (moment().format() > this.form.schedule.scheduleTime) {
          this.errors.schedule.scheduleTime = this.$t("Pages.Application.PushNotificationForm.ShouldBeInFuture");
          return false;
        } else {
          this.errors.schedule.scheduleTime = false;
        }
      }
      for (const key in this.errors[section]) {
        if (this.errors[section][key]) {
          return false;
        }
      }
      return true
    },
    async createPushNotification () {
      if (!this.validateForm('content')) {
        if (this.form.content.schedule) {
          if (!this.isDateTimeValid || !this.validateForm('schedule')) {
            return false
          }
        }
        return false
      } 
      if (!this.form.content.schedule) {
          const params = {
          application: this.$route.params.appId,
          body: this.form.content.content,
          schedule: false,
          title: this.form.content.name,
          content: this.form.content.deepLink || undefined
        }
        this.saving = true
        this.dontClearForm = true
        window.UIkit.modal.confirm(this.$t('Pages.Application.PushNotificationForm.PushNotificationConfirmation'), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Send')
            }}).then(async () => {
          this.dontClearForm = false
          let response
          if (this.appSelection === 'enabled') {
            const responses = []
            for (const app of this.form.selectedApps) {
              params.application = app.value;
              const notifResponse = await this.$store.dispatch('sendNotificationForAnApp', params)
              if (notifResponse.status?.includes('Success') || notifResponse.status?.includes('Sent')) {
                responses.push(notifResponse)
              } else {
                Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreationErrorForApp').replace('{{appName}}', app.label), notifResponse.message || notifResponse, 'error');
              }
            }
            this.saving = false
            if (responses.length === this.form.selectedApps.length) {
              Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreatedApps'), this.$t('Pages.Application.PushNotificationForm.NotificationCreatedAppDesc'));
              window.UIkit.modal('#' + this.modalId).hide();
            } else if (responses.length) {
              Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreatedPartial'), this.$t('Pages.Application.PushNotificationForm.NotificationCreatedPartialDesc'));
              window.UIkit.modal('#' + this.modalId).hide();
            }
            this.clearForm()
          } else {
            response = await this.$store.dispatch('sendNotificationForAnApp', params);
            this.saving = false
            if (response.status?.includes('Success') || response.status?.includes('Sent')) {
              Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreated'), this.$t('Pages.Application.PushNotificationForm.NotificationCreatedDesc'));
              window.UIkit.modal('#' + this.modalId).hide();
              this.clearForm()
            } else {
              Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreationError'), response.message || response, 'error');
            }
          }
        }, () => {
          this.dontClearForm = false
          this.saving = false
          window.UIkit.modal('#' + this.modalId).show();
        });
      } else {
        if (!this.isDateTimeValid || !this.validateForm('schedule')) {
          return false
        }
        const params = {
          application: this.$route.params.appId,
          body: this.form.content.content,
          schedule: true,
          scheduleType: this.form.schedule.timezoneLocale,
          scheduleWhen: this.form.schedule.scheduleTime,
          title: this.form.content.name,
          content: this.form.content.deepLink || undefined
        }
        let response
        this.saving = true
        if (this.appSelection === 'enabled') {
          const responses = []
          for (const app of this.form.selectedApps) {
            params.application = app.value
            const notifResponse = await this.$store.dispatch('sendNotificationForAnApp', params)
            if (notifResponse.status?.includes('Success') || notifResponse.status?.includes('Sent')) {
              responses.push(notifResponse)
            } else {
              Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreationErrorForApp').replace('{{appName}}', app.label), notifResponse.message || notifResponse, 'error');
            }
          }
          this.saving = false
          if (responses.length === this.form.selectedApps.length) {
            Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreatedApps'), this.$t('Pages.Application.PushNotificationForm.NotificationCreatedAppDesc'));
            window.UIkit.modal('#' + this.modalId).hide();
          } else if (responses.length) {
            Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreatedPartial'), this.$t('Pages.Application.PushNotificationForm.NotificationCreatedPartialDesc'));
            window.UIkit.modal('#' + this.modalId).hide();
          }
          this.clearForm()
        } else {
          response = await this.$store.dispatch('sendNotificationForAnApp', params)
          this.saving = false
          if (response.status?.includes('Success')) {
            Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreated'), this.$t('Pages.Application.PushNotificationForm.NotificationCreatedDesc'))
            window.UIkit.modal('#' + this.modalId).hide();
            this.clearForm()
          } else {
            Notification.showNotification(this.$t('Pages.Application.PushNotificationForm.NotificationCreationError'), response.message || response, 'error')
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .invalid-icon {
      color: #B94A48;
  }
</style>